import { ButtonC } from "Common/Buttons"

const NotFound = () => {
    const gotohome = () => {
        window.location.replace(window.location.origin)
    }
    return (
        <div className="d-flex justify-content-center aligh-items-center w-100" style={{ height: "calc(100vh - 100px)" }} >
            <div className=" flex-column" style={{ textAlign: "center" }}>
                <img src="/manage/images/404.png" alt="Not Found" />
                <div className="w-100 d-flex justify-content-center aligh-items-center">
                    <ButtonC size="lg" text="Go to home" click={gotohome} />
                </div>
            </div>
        </div >
    )
}

export default NotFound