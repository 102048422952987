import {
  GET_BIGCOMMERCE_PRODUCT_CART_LIST,
  GET_BIGCOMMERCE_PRODUCT_CART_LIST_ERROR,
  GET_BIGCOMMERCE_PRODUCT_CART_LIST_SUCCESS,
} from 'actions/types';

const initialState = {
  loading: true,
  error: false,
  productList: {},
  errorMessage: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BIGCOMMERCE_PRODUCT_CART_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_BIGCOMMERCE_PRODUCT_CART_LIST_SUCCESS:
      return {
        ...state,
        productList: action.payload,
        loading: false,
        error: false,
        errorMessage: '',
      };
    case GET_BIGCOMMERCE_PRODUCT_CART_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};
