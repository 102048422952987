/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import SpinnerLoader from './components/SpinnerLoader';
import NotFound from '404';

const DefaultLayout = lazy(() => import('./components'));
const Login = lazy(() => import('./components/Auth-v2'));
const ForgotPassword = lazy(() => import('./components/ForgotPassword'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));
const buildDateGreaterThan = (latestDate, currentDate) => {
  if (latestDate > currentDate) {
    return true;
  } else {
    return false;
  }
};
const App = () => {
  const [toastId, setToastId] = useState(null);

  const updateOnlineStatus = () => {
    const status = navigator.onLine ? 'online' : 'offline';

    if (status === 'offline') {
      if (toastId === null) {
        const toastId = toast.warn('You are offline', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          closeButton: false,
          draggable: false,
        });

        setToastId(toastId);
      }
    } else {
      if (toastId !== null) {
        toast.dismiss();
        setToastId(null);
        toast.success('You are online', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: false,
          closeButton: false,
          draggable: false,
        });
      }
    }
  };

  useEffect(() => {
    updateOnlineStatus();
    window.addEventListener('offline', updateOnlineStatus);
    window.addEventListener('online', updateOnlineStatus);

    return () => {
      window.removeEventListener('offline', updateOnlineStatus);
      window.removeEventListener('online', updateOnlineStatus);
    };
  }, [toastId]);
  useEffect(() => {
    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDateTime;
        const currentVersionDate = localStorage.getItem("buildDateTime");
        localStorage.setItem("buildDateTime", latestVersionDate);
        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        if (shouldForceRefresh) {
          window.location.reload(true);
        }
      });
  }, []);
  return (
    <Router>
      <Suspense fallback={<SpinnerLoader />}>
        <Routes>
          <Route
            path="/login"
            element={
              <Login />
            }
          />
          <Route
            exact
            path="/password_reset"
            element={
              <ForgotPassword />
            }
          />
          <Route
            exact
            path="/resetpassword/:hash"
            element={
              <ResetPassword />
            }
          />
          <Route
            path="/*"
            element={
              <DefaultLayout />
            }
          />
          <Route
            path="/404"
            element={
              <NotFound />
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
